import Img, { FluidObject } from 'gatsby-image'
import { isJapanese } from 'helpers/locale'
import React from 'react'
import styled, { css, CSSProp } from 'styled-components'

import Container from '../container'

const StyledFeatureImage = styled.div`
  ${({ theme }): CSSProp => css`
    margin: 5rem 0;
    @media only screen and ${theme.breakpoints.fromNormalScreen} {
      margin: 7rem 0;
    }
    h2 {
      position: relative;
      font-size: 3.8rem;
      color: ${theme.colours.tertiary};
      ${isJapanese() ?
        css`
          font-family: ${ theme.font.fontFamilyDefault };
          font-weight: ${ theme.font.fontWeightBold };
        `
        : css`font-family: ${ theme.font.fontFamilyAlt };`
      };
      margin: 0 0 0 2rem;
      line-height: 1.5;
      max-width: 20rem;
      margin-top: 1%;
      @media only screen and ${theme.breakpoints.fromNormalScreen} {
        margin: 0;
        font-size: 4rem;
        position: absolute;
        left: 50%;
        bottom: 30%;
        max-width: 60rem;
        max-width: 30rem;
      }
      @media only screen and ${theme.breakpoints.fromLargeScreen} {
        font-size: 6.5rem;
        max-width: 40rem;
        bottom: 20%;
        left: 45%;
        line-height: 1.3;
      }
    }
    .image {
      position: relative;
      margin: 0 0 5rem -2rem;
      @media only screen and ${theme.breakpoints.fromNormalScreen} {
        margin: 0 0 9.5rem;
      }
      .gatsby-image-wrapper {
        margin-bottom: -2.8rem;
        @media only screen and ${theme.breakpoints.fromNormalScreen} {
          width: 60%;
          margin: 0 0 0 -4rem;
        }
        @media only screen and ${theme.breakpoints.fromLargeScreen} {
          margin: 0;
          width: 50%;

        }
      }
    }
  `}
`

type FeatureImageProps = {
  title: string
  image: {
    image: FluidObject
    imageAlt: string
  }
}

const WholesaleFeatureImage = ({ title, image }: FeatureImageProps): JSX.Element => {
  const { image: imageObj, imageAlt } = image
  return title && imageObj ? (
    <StyledFeatureImage>
      <Container>
        <div className="image">
          {imageObj && <Img alt={imageAlt} fluid={imageObj} />}
          <h2>{title}</h2>
        </div>
      </Container>
    </StyledFeatureImage>
  ) : null
}

export default WholesaleFeatureImage
